<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate
  from '@/components/AlertingComponents/Sheet/SheetFragments/AlertTypeTemplate/Base/BaseAlertTemplate'
export default {
  name: 'IoStuckInComputing',
  props: ['alert'],
  components: {
    BaseAlertTemplate

  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Activation date',
          value: 'data.Detailed_data.Activation date'
        },
        {
          label: 'Number of days since activation date',
          value: 'data.Detailed_data.Number of days since activation date'
        }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style scoped>

</style>
